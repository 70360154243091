//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, provide, useRoute } from '@nuxtjs/composition-api';
import useMarketingTracking from '~/hooks/useMarketingTracking';
import LazyHydrate from 'vue-lazy-hydration';
import { useVSFContext } from '@vue-storefront/core';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    LazyHydrate
  },
  setup() {
    const route = useRoute();
    const { shouldShowCookieModal } = useMarketingTracking();

    // showSkyScraper based on domain provided and injected in cms-page.vue plp.vue and pdp.vue
    const vsfContext = useVSFContext();
    const wt = vsfContext.$novulo.config.state.getWt();
    const showSkyScraper = computed(() => {
      // hide for these domains .com .it .ie .fr .es .at .pg (wt in config/production.env)
      return ![27, 28, 29, 31, 37, 38, 39, 41, 42].includes(wt);
    });
    provide('showSkyScraper', showSkyScraper);

    return { route, shouldShowCookieModal };
  }
});
