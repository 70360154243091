















import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';

const flex = {
  start: 'flex-start',
  center: 'center',
  between: 'space-between',
  around: 'space-around',
  end: 'flex-end'
} as const;

type FlexType = keyof typeof flex;

type USPS = Array<{
  title: string;
  url: string;
}>;

export default defineComponent({
  name: 'Usps',
  props: {
    uspsList: {
      type: Array as PropType<USPS>,
      required: true
    },
    align: {
      type: String as PropType<FlexType>,
      required: false,
      default: 'start'
    }
  },
  setup(props) {
    const flexStyle = computed(() => flex[props?.align]);
    return {
      flexStyle
    };
  }
});
