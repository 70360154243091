
































import {
  computed,
  ref,
  onServerPrefetch,
  defineComponent,
  useRoute,
  useContext
} from '@nuxtjs/composition-api';
import { useFooter } from '@vue-storefront/novulo';
import useWindowResize from '~/hooks/useResizeWindow';

export default defineComponent({
  name: 'KEFooter',
  setup() {
    const { getFooter, footerResult } = useFooter();
    const { isDesktop } = useWindowResize();
    const route = useRoute();
    const { i18n } = useContext();

    const isHomePage = computed(() => {
      const locale = i18n.locale;
      const path = route.value.path;
      return (
        path === `/${locale}/` ||
        path === `/${locale}` ||
        path === '/' ||
        path === ''
      );
    });

    onServerPrefetch(async () => {
      await getFooter();
    });

    const uspsList = computed(() =>
      footerResult.value ? Object.values(footerResult.value.footer_usps) : []
    );

    const hasMegaList = computed(() =>
      isHomePage.value && footerResult.value
        ? footerResult.value?.footer_mega_list?.length
        : false
    );

    return {
      uspsList,
      isDesktop,
      hasMegaList
    };
  }
});
