import { render, staticRenderFns } from "./CheckmarkUsp.vue?vue&type=template&id=574ddf3c&"
import script from "./CheckmarkUsp.vue?vue&type=script&lang=ts&"
export * from "./CheckmarkUsp.vue?vue&type=script&lang=ts&"
import style0 from "./CheckmarkUsp.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsCheckmark16px: require('/var/www/packages/theme/components/icons/checkmark/Checkmark16px.vue').default,AtomsMarkdownRender: require('/var/www/packages/theme/components/atoms/MarkdownRender.vue').default,AtomsTextLink: require('/var/www/packages/theme/components/atoms/TextLink.vue').default})
